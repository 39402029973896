<script lang="ts" setup>
// layout settings
const route = useRoute()
const isHome = computed(() => route.path === '/' || route.fullPath.includes('/markets'))

// to load component based on screen size
const isMediumScreen = useMediaQuery('(min-width: 768px)')
const isLargeScreen = useMediaQuery('(min-width: 1024px)')
</script>

<template>
  <nav
    class="w-full flex items-center border border-gray-cultured dark:bg-gray-blackEerieLight dark:border-gray-raisin rounded-2.5 px-3.5 py-4 shadow-navbar z-11"
  >
    <div class="flex items-center">
      <NuxtLink class="mr-4 flex-inline items-center" to="/" aria-label="Hubble Exchange">
        <NavbarLogoOnly class="max-h-40px" />
        <ClientOnly>
          <Transition>
            <LazyNavbarLogoText
              v-if="isMediumScreen"
              class="ml-1.5 hidden md:block max-h-40px dark:text-white text-black"
            />
          </Transition>
        </ClientOnly>
      </NuxtLink>

      <div class="hidden lg:flex dark:opacity-100 opacity-20 h-6 mr-4 border-l-2 border-l-gray-700 border-t-0 border-b-0 border-r-0"></div>
    </div>

    <ClientOnly>
      <Transition>
        <LazyNavbarPageLinks v-if="isLargeScreen" />
      </Transition>
    </ClientOnly>
    <div class="ml-auto"></div>
    <ClientOnly>
      <TransitionGroup>
        <LazyNavbarVHubble v-if="isMediumScreen" class="ml-2" />
        <LazyReferralButton v-if="isMediumScreen" class="ml-2" />
        <LazyButtonMargin v-if="isMediumScreen" class="ml-2 dark:text-gray-romanSilver" />
      </TransitionGroup>
      <LazyWalletOptions v-if="isLargeScreen" class="mx-2" />
      <LazyMobileWalletOptions v-if="!isLargeScreen" class="ml-2" />
    </ClientOnly>

    <div class="ml-2 hidden">
      <!-- Keeping hidden to initialize mood as soon as possible -->
      <ColorMode class="p-2.76 md:px-7 md:py-3.25" />
    </div>

    <ClientOnly>
      <NavbarLayoutSetting v-if="isHome && isLargeScreen" class="ml-2" />
      <NavbarSetting v-else />
      <Transition>
        <LazyNavbarMobile v-if="!isLargeScreen" />
      </Transition>
    </ClientOnly>
  </nav>
</template>

<style scoped>
.dark .shadow-navbar {
  box-shadow: 0 32px 64px #00000080;
}
</style>
