<script lang="ts" setup>
import { useMagicKeys, useStorage } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { usePreferences } from '~/store/preferences'

const route = useRoute()
const isHome = computed(() => route.path === '/' || route.fullPath.includes('/markets'))

const settings = ref(false)
const preferences = usePreferences()
const { _ui } = storeToRefs(preferences)
useStorage('uiV2', _ui)

const keys = useMagicKeys()
const l1 = keys['l+1']
watch(l1, () => l1.value && preferences.resetUI())
const l2 = keys['l+2']
watch(l2, () => l2.value && preferences.changeUI('layout', 1))
const l3 = keys['l+3']
watch(l3, () => l3.value && preferences.changeUI('layout', 2))
const l4 = keys['l+4']
watch(l4, () => l4.value && preferences.changeUI('layout', 3))
const l5 = keys['l+5']
watch(l5, () => l5.value && preferences.changeUI('layout', 4))

const s1 = keys['s+1']
watch(s1, () => s1.value && preferences.changeUI('style', 0))
const s2 = keys['s+2']
watch(s2, () => s2.value && preferences.changeUI('style', 1))

const c1 = keys['c+1']
watch(c1, () => c1.value && preferences.changeUI('bgColor', 0))
const c2 = keys['c+2']
watch(c2, () => c2.value && preferences.changeUI('bgColor', 1))
const c3 = keys['c+3']
watch(c3, () => c3.value && preferences.changeUI('bgColor', 2))
const c4 = keys['c+4']
watch(c4, () => c4.value && preferences.changeUI('bgColor', 3))
const c5 = keys['c+5']
watch(c5, () => c5.value && preferences.changeUI('bgColor', 4))
</script>

<template>
  <Tour :step="'layout'">
    <button
      class="fv-hide !p-1.75 rounded-2.5 !hf-text-current bg-gray-cultured b-hf-in dark:text-gray-romanSilver dark:bg-gray-eerie dark:hover:bg-gr-blueOxfordMidnightIndigoPurple common-transition"
      :class="{
        'bgr-border-bppywb-15': preferences.ui.bgColor === 0 || !isHome || !preferences.isDark,
        'bgr-border-violet-180': preferences.ui.bgColor === 1 && isHome && preferences.isDark,
        'bgr-border-lilac-180': preferences.ui.bgColor === 2 && isHome && preferences.isDark,
        'bgr-border-jeans-180': preferences.ui.bgColor === 3 && isHome && preferences.isDark,
        'bgr-border-iris-180': preferences.ui.bgColor === 4 && isHome && preferences.isDark,
      }"
      @click="settings = true"
    >
      <IconLayout class="w-6.5 h-6.5" />
      <LazySettingsLayout v-if="settings" :show="settings" @close="settings = false" />
    </button>
  </Tour>
</template>
