<script lang="ts" setup>
import { usePreferences } from '~/store/preferences'

const route = useRoute()
const isHome = computed(() => route.path === '/' || route.fullPath.includes('/markets'))
const preferences = usePreferences()

const settings = ref(false)
</script>

<template>
  <button
    class="fv-hide !p-1.75 rounded-2.5 !hf-text-current bg-gray-cultured bgr-border-bppywb-15 b-hf-in dark:text-gray-romanSilver dark:bg-gray-eerie dark:hover:bg-gr-blueOxfordMidnightIndigoPurple common-transition"
    :class="{
      'bgr-border-bppywb-15': preferences.ui.bgColor === 0 || !isHome || !preferences.isDark,
      'bgr-border-violet-180': preferences.ui.bgColor === 1 && isHome && preferences.isDark,
      'bgr-border-lilac-180': preferences.ui.bgColor === 2 && isHome && preferences.isDark,
      'bgr-border-jeans-180': preferences.ui.bgColor === 3 && isHome && preferences.isDark,
      'bgr-border-iris-180': preferences.ui.bgColor === 4 && isHome && preferences.isDark,
    }"
    @click="settings = true"
  >
    <IconSettingFilledAnt class="w-6.5 h-6.5" />
    <LazySettingsModal v-if="settings" :show="settings" @close="settings = false" />
  </button>
</template>
