<script lang="ts" setup>
import { useMediaQuery } from '@vueuse/core'
import { useData } from '~~/store/data'
import { usePreferences } from '~~/store/preferences'

import '~~/asset/css/gradients.css'

// Enable when supports multi language
// import { useI18n } from 'vue-i18n'
// const { locale } = useI18n({ useScope: 'global' })
// const lang = computed((): string => locale.value as string)

const data = useData()
onMounted(() => data.fetchBanners())
const preferences = usePreferences()

const route = useRoute()
const isThemePage = computed(() => route.path === '/' || route.fullPath.includes('/markets'))

const isLargeScreen = useMediaQuery('(min-width: 1024px)')
const isLearnVisible = computed(() => preferences.tourStepKey === '' && isLargeScreen.value)
</script>

<template>
  <div id="layout" class="flex flex-col lg:px-5.5 lg:py-5.5 px-4 py-4 bg-white text-black dark:text-white dark:bg-dark"
    :class="{
      'bg-gr-bgcViolet-180': preferences.ui.bgColor === 1 && isThemePage && preferences.isDark,
      'bg-gr-bgcLilac-180': preferences.ui.bgColor === 2 && isThemePage && preferences.isDark,
      'bg-gr-bgcJeans-180': preferences.ui.bgColor === 3 && isThemePage && preferences.isDark,
      'bg-gr-bgcIris-180': preferences.ui.bgColor === 4 && isThemePage && preferences.isDark,
    }">
    <!-- <Html :lang="lang || 'en'"> </Html> -->
    <Html lang="en">

    </Html>

    <Transition name="slide-fade">
      <LazyNavbarBanner v-if="data.banner !== null" :banner="data.banner" @close="data.banner = null" />
    </Transition>
    <Navbar />

    <main class="flex flex-col flex-grow py-2.50">
      <slot />
    </main>
    <LazySnack />
    <Footer />

    <!-- Animations -->
    <LazyRainbowMouse v-if="preferences.mouseTail" />
    <LazyConfetti v-if="preferences.confetti && preferences.showConfetti" @close="preferences.showConfetti = false" />
    <LazyCommandPalette />


    <ClientOnly>
      <LazyLearn v-if="isLearnVisible" />
    </ClientOnly>
    <div id="teleported"></div>
  </div>
</template>
